import React from 'react';
import { css } from '@emotion/react';
import PulseLoader from 'react-spinners/PulseLoader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #23c4bd;
  position: absolute;
  top: 47%;
  left: 47%;
`;

class Spinner extends React.PureComponent {

	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="spinnerWrapper">
				<PulseLoader color={'#23c4bd'} loading={true} css={override} size={30} />
			</div>
		)
	}
	
}

export default Spinner;