import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import logo from "./logo.svg";
import "./css/reset.css";
import "./css/main.css";
import Member from "./pages/userManagement/UserManagements";
import EstimateManagements from "./pages/estimateManagement/EstimateList";
import ConstructionList from "./pages/constructionManagement/ConstructionList";
import ConstructionDetail from "./pages/constructionManagement/ConstructionDetail";
import ConstructionAdd from "./pages/constructionManagement/ConstructionAdd";
import PlaceList from "./pages/constructionManagement/PlaceList";
import PlaceDetail from "./pages/constructionManagement/PlaceDetail";
import PlaceAdd from "./pages/constructionManagement/PlaceAdd";
import JobofferMangement from "./pages/humanManagement/JobofferMangement";
import JobofferDetail from "./pages/humanManagement/JobofferDetail";
import JobsearchMangement from "./pages/humanManagement/JobsearchMangement";
import JobsearchDetail from "./pages/humanManagement/JobsearchDetail";
import EquipRental from "./pages/equipManagement/EquipRental";
import EquipRentalDetail from "./pages/equipManagement/EquipRentalDetail";
import EquipLease from "./pages/equipManagement/EquipLease";
import EquipLeaseDetail from "./pages/equipManagement/EquipLeaseDetail";
import LaborList from "./pages/laborManagement/LaborList";
import LaborDetail from "./pages/laborManagement/LaborDetail";
import PostManagement from "./pages/issueManagement/PostManagement";
import PostDetail from "./pages/issueManagement/PostDetail";
import CommentManagement from "./pages/issueManagement/CommentManagement";
import Select from "./components/Select";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Login from "./pages/Login";
import LaborAdd from "./pages/laborManagement/LaborAdd";
import TypeAdd from "./pages/laborManagement/TypeAdd";
import TypeList from "./pages/laborManagement/TypeList";
import TermList from "./pages/termManagement/TermList";
import TermDetail from "./pages/termManagement/TermDetail";
import PushSend from "./pages/pushManagement/PushSend";
import BannerList from "./pages/bannerManagement/BannerList";
import BannerDetail from "./pages/bannerManagement/BannerDetail";
import EquipList from "./pages/equipManagement/EquipList";
import EquipDetail from "./pages/equipManagement/EquipDetail";
import OwnerList from "./pages/equipManagement/OwnerList";
import OwnerDetail from "./pages/equipManagement/OwnerDetail";
import BannerPageAdd from "./pages/bannerManagement/BannerPageAdd";
import JobCommentManagement from "./pages/humanManagement/JobCommentManagement";
import ReportManagement from "./pages/reportManagement/ReportManagement";

function App() {
  const auth = useSelector((state) => state.auth);
  console.log(auth);
  return (
    <div id="wrap" className="clearfix">
      <BrowserRouter>
        <Header />
        {auth.isLogin && <Sidebar />}
        {/* <Sidebar /> */}
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          {!auth.isLogin && <Redirect to="/login" />}
          <Route exact path="/member" component={Member} />
          <Route exact path="/estimate" component={EstimateManagements} />
          <Route exact path="/construction/list" component={ConstructionList} />
          <Route
            exact
            path="/construction/detail/:idx"
            component={ConstructionDetail}
          />
          <Route exact path="/construction/add" component={ConstructionAdd} />
          <Route exact path="/construction/placelist" component={PlaceList} />
          <Route
            exact
            path="/construction/placedetail/:idx"
            component={PlaceDetail}
          />
          <Route
            exact
            path="/construction/place/add/:idx"
            component={PlaceAdd}
          />
          <Route
            exact
            path="/humanManagement/joboffer"
            component={JobofferMangement}
          />
          <Route
            exact
            path="/humanManagement/joboffer/detail/:idx"
            component={JobofferDetail}
          />
          <Route
            exact
            path="/humanManagement/jobsearch"
            component={JobsearchMangement}
          />
          <Route
            exact
            path="/humanManagement/jobsearch/detail/:idx"
            component={JobsearchDetail}
          />
          <Route
            exact
            path="/humanManagement/jobCommentManagement"
            component={JobCommentManagement}
          />
          <Route exact path="/equip/rental" component={EquipRental} />
          <Route
            exact
            path="/equip/rental/detail/:idx"
            component={EquipRentalDetail}
          />
          <Route exact path="/equip/lease" component={EquipLease} />
          <Route
            exact
            path="/equip/lease/detail/:idx"
            component={EquipLeaseDetail}
          />
          <Route exact path="/equip/list" component={EquipList} />
          <Route exact path="/equip/detail/:idx" component={EquipDetail} />
          <Route exact path="/owner/list" component={OwnerList} />
          <Route exact path="/owner/detail/:idx" component={OwnerDetail} />
          <Route exact path="/labor/list" component={LaborList} />
          <Route exact path="/labor/add" component={LaborAdd} />
          <Route exact path="/labor/detail/:idx" component={LaborDetail} />
          <Route exact path="/labor/type" component={TypeList} />
          <Route exact path="/labor/type/add" component={TypeAdd} />
          <Route
            exact
            path="/issue/postManagement"
            component={PostManagement}
          />
          <Route
            exact
            path="/issue/postManagement/detail/:idx"
            component={PostDetail}
          />
          <Route
            exact
            path="/issue/commentManagement"
            component={CommentManagement}
          />
          <Route exact path="/terms/termManagement" component={TermList} />
          <Route
            exact
            path="/terms/termManagement/detail/:idx"
            component={TermDetail}
          />
          <Route exact path="/push/send" component={PushSend} />
          <Route exact path="/banner/list" component={BannerList} />
          <Route exact path="/banner/add" component={BannerPageAdd} />
          <Route exact path="/banner/detail/:idx" component={BannerDetail} />
          <Route exact path="/report/list" component={ReportManagement} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
