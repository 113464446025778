import React from 'react';
import UnstyledSelectsMultiple from '../../components/Select';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';

class LaborAdd extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            type: '',
            name: '',
            phone: '',
            address: '',
            career: '',
            simple: '',
            description: '',
            file: {},
            typeList: [],
        })
    }

    componentDidMount() {
        // this.getData()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    handleDate = e => {
        let val = e.target.value
        val = val.replace('--', '-')
        if (val.match(/^\d{5}$/) !== null) {
            val = String(val).slice(0, 4) + '-' + String(val).slice(4, 5)
        } else if (val.match(/^\d{4}\-\d{3}$/) !== null) {
            val = String(val).slice(0, 7) + '-' + String(val).slice(7, 8)
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
  
            reader.onload = e => {
              this.preview.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
  
            this.setState({file: e.target.files[0]})
          }
    }

    handleType = type => {
        this.setState({type})
    }

    toggleIsOpen = () => this.setState({isOpen: !this.state.isOpen})

    handleAddress = data => {
        this.setState({
            isOpen: false,
            address: data.address
        })
    }

    getData = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                api.get(`labor/type/all`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        this.setState({
                            typeList: result.data.data.result
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    onSubmit = async () => {
        const { type, name, phone, address, career, simple, description, file } = this.state
        if (type.trim().length === 0) {
            alert('상담분류를 선택해주세요')
            return false
        } else if (name.trim().length === 0) {
            alert('이름을 입력해주세요')
            return false
        } else if (phone.trim().length === 0) {
            alert('연락처을 입력해주세요')
            return false
        } else if (career.trim().length === 0) {
            alert('연차를 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                let formData = new FormData()
                formData.append('laborName', name)
                formData.append('laborPhone', phone)
                formData.append('laborAddress', address)
                formData.append('laborType', type)
                formData.append('laborCareer', career)
                formData.append('laborSimple', simple)
                formData.append('laborDescription', description)
                if (file.size !== undefined) {
                    formData.append('laborImage', file)
                }
                api.post(`labor/add`, formData, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('추가 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { type, name, phone, address, career, simple, description, typeList } = this.state
        return (
        <>
            <div className="content">
                <h2>노무사추가</h2>
                <div className="location">
                    <span>노무관리</span>
                    <span>노무현황</span>
                    <span>노무사추가</span>
                </div>            
                <div className="detail">
                    <table>
                        <caption>구직신청 상세</caption>
                        <tbody>
                            <tr>
                                <th>상담분류</th>
                                <td>
                                    {/* <UnstyledSelectsMultiple value={type} list={typeList} handleType={this.handleType} /> */}
                                    <input 
                                        type="text" 
                                        name="type" 
                                        value={type} 
                                        onChange={this.handleChange} 
                                        placeholder="상담분류" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        value={name} 
                                        onChange={this.handleChange} 
                                        placeholder="이름" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="phone" 
                                        value={phone} 
                                        onChange={this.handleChange} 
                                        placeholder="연락처" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="address" 
                                        value={address} 
                                        onChange={this.handleChange} 
                                        placeholder="주소" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>사진</th>
                                <td>
                                    <input 
                                        type="file" 
                                        style={{display: "none" }}
                                        name="file" 
                                        accept="image/*" 
                                        onChange={this.handleFile}
                                        ref={ref => this.fileInput = ref}
                                    /> 
                                    <img 
                                        src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAA5SURBVHgB7dUxEQAgDATBgH9Z8QUKaPhhaPYMbHlVQd296rJZnwKDwWAw+NhI1haUmX4MBoPB4CdtYs0LGDW2IbcAAAAASUVORK5CYII='} 
                                        ref={ref => this.preview = ref} 
                                        className="btn_add"
                                        alt="사진"
                                        onClick={() => this.fileInput.click()}
                                    />
                                    {/* <button className="btn_add">사진 추가</button> */}
                                </td>
                            </tr>
                            <tr>
                                <th>연차</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="career" 
                                        value={career} 
                                        onChange={this.handleChange} 
                                        placeholder="연차" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>간단 소개글</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="simple" 
                                        value={simple} 
                                        onChange={this.handleChange} 
                                        placeholder="간단 소개글" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>소개글</th>
                                <td>
                                    <textarea 
                                        name='description'
                                        value={description} 
                                        onChange={this.handleChange}  
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">추가하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(LaborAdd);