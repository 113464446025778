import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class TermList extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            pageNo: 1,
            list: [],
            paging: {},
            redirect: null
        }
    }

    async componentDidMount() {
        this.getList()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleAllCheck = e => {
        let list = this.state.list
        list.forEach(val => val.isChecked = e.target.checked)
        this.setState({list}, () => this.forceUpdate())
    }

    checkList = idx => {
        let list = this.state.list
        list[idx].isChecked = !list[idx].isChecked
        this.setState({list}, () => this.forceUpdate())
    }


    // onDelete = async (v) => {
    //     const { list } = this.state
    //     let arr = list.filter(val => val.isChecked)

    //     if(arr.length === 0) {
    //         alert('삭제할 항목을 체크해주세요')
    //         return false
    //     }
    //     arr = arr.map(val => {return val.type_idx})

    //     await this.setState({
    //         isLoading: true
    //     }, () => {
    //         try {
    //             const config = {
    //                 headers: {
    //                   'Content-Type': 'application/json',
    //                   'Access-Control-Allow-Origin': '*',
                    //   'Access-Control-Allow-Headers': '*',
    //                   'authorization': `Bearer ${this.props.token}`
    //                 },
    //             };
    //             const body = {
    //                 idx: String(arr),
    //                 status: v
    //             }
    //             api.post(`labor/type/update`, body, config).then((result) => {
    //                 console.log(result)
    //                 if(result.data.success) {
    //                     alert('수정 성공')
    //                     this.getList()
    //                 } else {
    //                     alert(result.data.message)
    //                 }
    //             })
    //         } catch (err) {
    //             alert('서버와 통신에 실패');
    //             console.log('err', err);
    //         } finally {
    //             this.setState({isLoading: false})
    //         }
    //     })

    // }

    getList = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`terms/list/${this.state.pageNo}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        let list = result.data.data.result
                        list = list.map(val => {
							// val.isChecked = false
                            val.timestamp_edit = moment(val.timestamp_edit).format('YYYY-MM-DD')
                            return val
                        })
                        this.setState({
                            paging: result.data.data.paging,
                            list: list,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { paging, pageNo, redirect } = this.state
        
        const handlePageNo = this.handlePageNo

        function getPaging() {
            let arr = []
            if (paging) {
                for(let i = paging.firstPageNo; i <= paging.finalPageNo; i++) {
                    arr.push(
                        <li className={pageNo === i ? "on" : null}>
                            <button onClick={() => handlePageNo(i)} name="pageNo" value={i}>
                                {i}
                            </button>
                        </li>
                    )
                }
            }
            return arr
        }

        return (
        <>
            <div className="content">
                <h2>약관 관리</h2>
                <div className="location">
                    <span>약관관리</span>
                    <span>약관관리</span>
                </div>
                <div className="list click">
                    <div className="scroll">
                        <table>
                            <caption>약관 리스트</caption>
                            <thead>
                                <tr>
                                    {/* <th className="chk">
                                        <p className="chk1">
                                            <input type="checkbox" id="chk0" onClick={this.handleAllCheck} />
                                            <label htmlFor="chk0">체크박스</label>
                                        </p>
                                    </th> */}
                                    <th>No.</th>
                                    <th>제목</th>
                                    <th>수정일</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.list && this.state.list.map((val, idx) => {
                                        return (
                                            <tr>
                                                {/* <td className="chk">
                                                    <p className="chk1">
                                                        <input 
                                                            type="checkbox" 
                                                            id={`chk${val.term_idx}`} 
                                                            checked={val.isChecked} 
                                                            onChange={() => this.checkList(idx)} 
                                                        />
                                                        <label htmlFor={`chk${val.term_idx}`}>체크박스</label>
                                                    </p>
                                                </td> */}
                                                <td onClick={() => this.props.history.push(`/terms/termManagement/detail/${val.term_idx}`)}>{val.term_idx}</td>
                                                <td onClick={() => this.props.history.push(`/terms/termManagement/detail/${val.term_idx}`)}>{val.term_title}</td>
                                                <td onClick={() => this.props.history.push(`/terms/termManagement/detail/${val.term_idx}`)}>{val.timestamp_edit}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className="btn_wrap">
                        <button className="btn btn1" onClick={() => this.onDelete(1)}>비활성화</button>
                        <button style={{marginLeft: 10}} className="btn btn2" onClick={() => this.onDelete(0)}>활성화</button>
                        <button type="button" onClick={() => this.props.history.push('/labor/type/add'})} className="btn btn3 fr">타입 추가</button>
                    </div> */}
                    <div className="paging">
                        <ul className="clearfix">
                            <li><button onClick={this.checkPrev}>이전 페이지</button></li>
                            {getPaging()}
                            <li><button onClick={this.checkNext}>다음 페이지</button></li>
                        </ul>
                    </div>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(TermList)