import React, { useState } from 'react';
import DaumPostCode from 'react-daum-postcode';

const DaumPost = (props) => {
    const handleComplete = (data) => {
		// 주소 선택 시 실행되는 함수
		// 부모의 handleAddress를 props로 받으면 선택한 주소 data값 넘기면서 함수 실행합니다
		props.handleAddress(data)
    }
    return (<DaumPostCode onComplete={handleComplete} className="post-code" />);
}
export default DaumPost;