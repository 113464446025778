import React from "react";
import api from "../../api/api";
import axios from "axios";
import Spinner from "../../components/Spinner";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";

class BannerDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      to: "",
      order: "",
      src: "",
      type: "",
      file: {},
      redirect: null,
    };
  }

  componentDidMount() {
    console.log(this.props.match.params.idx);
    this.getDetail();
  }

  getDetail = async () => {
    await this.setState(
      {
        isLoading: true,
      },
      () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              authorization: `Bearer ${this.props.token}`,
            },
          };
          api
            .get(`banner/detail/${this.props.match.params.idx}`, config)
            .then((result) => {
              console.log(result);
              if (result.data.success) {
                const res = result.data.data.result;
                this.setState({
                  name: res.banner_name,
                  to: res.banner_to,
                  order: res.banner_order,
                  type: res.banner_status,
                  src:
                    String(res.banner_image).slice(0, 4) === "file"
                      ? "https://momobuild.s3.ap-northeast-2.amazonaws.com/" +
                        res.banner_image
                      : res.banner_image,
                  isLoading: false,
                });
              } else {
                alert(result.data.message);
              }
            });
        } catch (err) {
          alert("서버와 통신에 실패");
          console.log("err", err);
        } finally {
          this.setState({ isLoading: false });
        }
      }
    );
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.preview.src = e.target.result;
      };
      reader.readAsDataURL(e.target.files[0]);

      this.setState({ file: e.target.files[0] });
    }
  };

  onSubmit = async () => {
    const { name, to, order, type, file, src } = this.state;
    if (name.trim().length === 0) {
      alert("배너 이름을 입력해주세요");
      return false;
    } else if (to.trim().length === 0) {
      alert("배너 이동 URL을 입력해주세요");
      return false;
    } else if (order.length === 0) {
      alert("배너 순서를 입력해주세요");
      return false;
    }
    await this.setState(
      {
        isLoading: true,
      },
      () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": "*",
              authorization: `Bearer ${this.props.token}`,
            },
          };
          let formData = new FormData();
          formData.append("bannerIdx", this.props.match.params.idx);
          formData.append("bannerName", name);
          formData.append("bannerTo", to);
          formData.append("bannerOrder", order);
          formData.append("bannerStatus", type);
          if (file.size !== undefined) {
            formData.append("bannerImage", file);
          } else {
            let str = src;
            if (
              str === undefined ||
              str === null ||
              str === "undefined" ||
              str === "null"
            ) {
              str = "";
            } else if (
              String(src).startsWith(
                "https://momobuild.s3.ap-northeast-2.amazonaws.com/"
              )
            ) {
              str = String(src).replace(
                "https://momobuild.s3.ap-northeast-2.amazonaws.com/",
                ""
              );
            }
            formData.append("originalFile", str);
          }
          api.post(`banner/update`, formData, config).then((result) => {
            console.log(result);
            if (result.data.success) {
              alert("수정 성공");
              this.props.history.goBack();
            } else {
              alert(result.data.message);
            }
          });
        } catch (err) {
          alert("서버와 통신에 실패");
          console.log("err", err);
        } finally {
          this.setState({ isLoading: false });
        }
      }
    );
  };

  render() {
    const { name, to, order, src, type, file } = this.state;

    return (
      <>
        <div className="content">
          <h2>배너 수정</h2>
          <div className="location">
            <span>배너 관리</span>
            <span>배너 수정</span>
          </div>
          <div className="detail">
            <table>
              <caption>건설사 상세</caption>
              <tbody>
                <tr>
                  <th>배너 이름</th>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                      placeholder="배너 이름"
                    />
                  </td>
                </tr>
                <tr>
                  <th>배너 이동 URL</th>
                  <td>
                    <input
                      type="text"
                      name="to"
                      value={to}
                      onChange={this.handleChange}
                      placeholder="web.buildmomo.com/ 이후의 URL만 입력"
                    />
                  </td>
                </tr>
                <tr>
                  <th>배너 순서</th>
                  <td>
                    <input
                      type="text"
                      name="order"
                      value={order}
                      onChange={this.handleChange}
                      placeholder="배너 순서(숫자만)"
                    />
                  </td>
                </tr>
                <tr>
                  <th>배너 이미지</th>
                  <td>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      name="file"
                      accept="image/*"
                      onChange={this.handleFile}
                      ref={(ref) => (this.fileInput = ref)}
                    />
                    <img
                      ref={(ref) => (this.preview = ref)}
                      src={src}
                      className="logoImage"
                      alt=""
                    />
                    <button
                      className="btn btn4 ml10"
                      onClick={() => this.fileInput.click()}
                    >
                      파일찾기
                    </button>
                  </td>
                </tr>
                <tr>
                  <th>배너 상태</th>
                  <td>
                    <input
                      type="radio"
                      name="type"
                      value={0}
                      onChange={this.handleChange}
                      placeholder="활성화"
                      id="radio1"
                      checked={type.toString() === "0"}
                    />
                    <label for="radio1">활성화</label>
                    <input
                      type="radio"
                      name="type"
                      value={1}
                      onChange={this.handleChange}
                      placeholder="비활성화"
                      id="radio2"
                      checked={type.toString() === "1"}
                      style={{ marginLeft: 20, marginTop: 10 }}
                    />
                    <label for="radio2">비활성화</label>
                  </td>
                </tr>
              </tbody>
            </table>
            <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">
              수정하기
            </button>
            {/* <button 
                        onClick={() => this.props.history.push(`/construction/place/add/${this.props.match.params.idx}`)}
                        className="btn btn3 w170 top_btn" 
                        style={{top: 100, backgroundColor: '#000'}}
                    >
                        배너수정
                    </button> */}
          </div>
          {this.state.isLoading && <Spinner />}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.user.sessionToken,
});
export default connect(mapStateToProps)(BannerDetail);
