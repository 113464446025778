import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Link,
  useHistory,
  Route,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import api from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN, LOGINTYPE, LOGOUT } from '../../Redux/userSlice';
import axios from 'axios';
import Spinner from '../../components/Spinner';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const { user, isLogin, Result, Type } = useSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [id, setId] = useState('');
  const onClickLogin = async e => {
    e.preventDefault();

    setIsLoading(true)

    let body = {
      email: id,
      password: password,
      // Type: inputStatus ? 'company' : 'admin',
      // Type: 'admin',
    };
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
        },
      };
    //   const res = await api.post('auth/signin', body, config); // 서버 올리면 수정 ... JY
      const res = await api.post('auth/signin', body, config);
      console.log(res);
      if (res.data.success) {
        // dispatch(LOGIN(res?.data));
        dispatch(LOGIN({ isLogin: true, sessionToken: res?.data.data }));
        // dispatch(LOGINTYPE(inputStatus ? 'company' : 'admin'));
        dispatch(LOGINTYPE('admin'));
        history.push('member');
        // alert('로그인을 성공하였습니다');
      }
    } catch (err) {
      alert('서버와 통신에 실패');
      console.log('err', err);
    } finally {
      setIsLoading(false)
    }
  };
  useEffect(() => {
    if (isLogin === true) {
    //   history.push('/User');
    dispatch(LOGOUT());
    }
  }, []);

  return (
    <Form>
      {/* <Radio
        click={!inputStatus}
        handleClickAdmin={handleClickAdmin}
        text="관리자"
        style={{ position: 'relative', right: 280 }}
      /> */}
      <Article>
        {/* <AdminText>
          <Radio
            click={inputStatus}
            handleClickAdmin={handleClickAdmin}
            text="기업 로그인"
          />
          <LinkStyle to="/signUp">
            <Radio
              click={!naviStatus}
              handleClickAdmin={handleClickSignUp}
              text="기업회원 신규가입"
            />
          </LinkStyle>
        </AdminText> */}

        <LoginWrapper>
          <IdTextBox>
            <div>아이디</div>
            <div>비밀번호</div>
          </IdTextBox>
          <InputWrap>
            <LoginInputBox
              onChange={e => setId(e.target.value)}
              // pattern="[A-Za-z]+"
            />
            <LoginInputBox
              type="password"
              onChange={e => setPassword(e.target.value)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <span style={{ marginTop: 20 }}>
                *아이디는 영문 대소문자를 구분합니다.
              </span>
            </div>
          </InputWrap>
          <LoginButton onClick={onClickLogin}>로그인</LoginButton>
        </LoginWrapper>
      </Article>
      {isLoading && <Spinner />}
    </Form>
  );
};

export default Login;

const LinkStyle = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
`;

const Article = styled.article`
  height: 35vh;
  width: 40vw;
  left: 50%;
  display: flex;
  // padding-top: 50px;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid lightgray;
  border-radius: 7px;
`;

const AdminText = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 210px 10px 170px;
`;

const LoginWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IdTextBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 30px;
  margin-bottom: 17px;
  font-weight: 800;
  font-size: 15px;

  div:first-child {
    margin-bottom: 45px;
  }
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;

  input:first-child {
    margin-bottom: 15px;
  }
`;

const LoginInputBox = styled.input`
  width: 18vw;
  height: 5vh;
  border: 1px solid lightgray;
  border-radius: 5px;
`;

const ChangeBox = styled.span`
  text-align: right;
  margin-top: 20px;
  color: #23c4bd;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 20%;
`;

const LoginButton = styled.button`
  color: white;
  background-color: #23c4bd;
  width: 8vw;
  height: 11vh;
  margin-left: 20px;
  margin-bottom: 20px;
  border: 2px solid #23c4bd;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
`;
