import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import DaumPost from '../../components/DaumPost'

class ConstructionAdd extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            name: '',
            address: '',
            addressDetail: '',
            file: {}
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
  
            reader.onload = e => {
              this.preview.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
  
            this.setState({file: e.target.files[0]})
          }
    }

    toggleIsOpen = () => this.setState({isOpen: !this.state.isOpen})

    handleAddress = data => {
        this.setState({
            isOpen: false,
            address: data.address
        })
    }

    onSubmit = async () => {
        const { name, address, addressDetail, file } = this.state
        if (name.trim().length === 0 || address.trim().length === 0) {
            alert('건설사명과 주소를 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                let formData = new FormData()
                formData.append('company_name', name)
                formData.append('company_address', address)
                formData.append('company_address_detail', addressDetail)
                if (file.size !== undefined) {
                    formData.append('company_logo', file)
                }
                api.post(`company/add`, formData, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('등록 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { name, address, addressDetail, file } = this.state
        return (
        <>
            <div className="content">
                <h2>건설사 등록</h2>
                <div className="location">
                    <span>건설사관리</span>
                    <span>건설사리스트</span>
                    <span>건설사추가</span>
                </div>
                <div className="detail">
                    <table>
                        <caption>건설사 등록</caption>
                        <tbody>
                            <tr>
                                <th>건설사 명</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        value={name} 
                                        onChange={this.handleChange} 
                                        placeholder="건설사 명" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>건설사 로고</th>
                                <td>
                                    <input 
                                        type="file" 
                                        style={{display: "none" }}
                                        name="file" 
                                        accept="image/*" 
                                        onChange={this.handleFile}
                                        ref={ref => this.fileInput = ref}
                                    /> 
                                    <img 
                                        ref={ref => this.preview = ref} 
                                        src={require("../../img/construction_logo2.png")} 
                                        className="logoImage"
                                    />
                                    <button className="btn btn4 ml10" onClick={() => this.fileInput.click()}>파일찾기</button>
                                </td>
                            </tr>
                            <tr>
                                <th>건설사 위치</th>
                                <td>
                                    <div className="address">
                                        <input 
                                            type="text"
                                            placeholder="주소" 
                                            name='address' 
                                            value={address} 
                                            onChange={this.handleChange} 
                                        />
                                        <button onClick={this.toggleIsOpen} className="btn btn2">주소찾기</button>
                                        <input 
                                            type="text"
                                            placeholder="상세주소 입력" 
                                            name='addressDetail' 
                                            value={addressDetail} 
                                            onChange={this.handleChange} 
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">추가하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
                <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleIsOpen}>
                    <DaumPost handleAddress={this.handleAddress} />
                </Modal>
            </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(ConstructionAdd)