import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';

class JobsearchDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            workIdx: '',
            title: '',
            phone: '',
            startDate: '',
            endDate: '',
            job: '',
            job2: '',
            team: '',
            safe: false,
            etc: ''
        })
    }

    componentDidMount() {
        console.log(this.props.match.params.idx)
        this.getDetail()
    }

    getDetail = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`job/detail/${this.props.match.params.idx}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        const res = result.data.data.result
                        let job = ''
                        let team = ''
                        let safe = false
                        let divide = res.work_divide_first
                        if (divide !== undefined && divide !== null && String(divide).trim() !== '') {
                            const arr = String(divide).split(':')
                            if (arr.length > 2) {
                                safe = true
                            }
                            job = arr[0]
                            team = arr[1]
                        }
                        this.setState({
                            title: res.work_title,
                            phone: res.work_user_phone,
                            startDate: moment(res.work_period_start).format('YYYY-MM-DD'),
                            endDate: moment(res.work_period_end).format('YYYY-MM-DD'),
                            job,
                            job2: res.work_divide_second,
                            team,
                            safe,
                            etc: res.work_etc,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSafe = e => this.setState({safe: !this.state.safe})
    
    handleDate = e => {
        let val = e.target.value
        val = val.replace('--', '-')
        if (val.match(/^\d{5}$/) !== null) {
            val = String(val).slice(0, 4) + '-' + String(val).slice(4, 5)
        } else if (val.match(/^\d{4}\-\d{3}$/) !== null) {
            val = String(val).slice(0, 7) + '-' + String(val).slice(7, 8)
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
  
            reader.onload = e => {
              this.preview.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
  
            this.setState({file: e.target.files[0]})
          }
    }

    toggleIsOpen = () => this.setState({isOpen: !this.state.isOpen})

    handleAddress = data => {
        this.setState({
            isOpen: false,
            address: data.address
        })
    }

    onSubmit = async () => {
        const { title, phone, startDate, endDate, job, job2, team, safe, etc } = this.state
        if (title.trim().length === 0) {
            alert('이름을 입력해주세요')
            return false
        } else if (phone.trim().length === 0) {
            alert('연락처를 입력해주세요')
            return false
        } else if (!moment(startDate).isValid()) {
            alert('시작일을 양식에 맞게 입력해주세요')
            return false
        } else if (!moment(endDate).isValid()) {
            alert('종료일을 양식에 맞게 입력해주세요')
            return false
        } else if (job.trim().length === 0) {
            alert('직종을 입력해주세요')
            return false
        } else if (job2.trim().length === 0) {
            alert('직종 상세를 입력해주세요')
            return false
        } else if (team.trim().length === 0) {
            alert('개인/팀 여부를 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                let divideFirst = job + ':' + team
                if (safe) {
                    divideFirst = divideFirst + ':안전교육이수'
                }
                const body = {
                    workIdx: this.props.match.params.idx,
                    name: title,
                    phone,
                    startDate,
                    endDate,
                    divideFirst,
                    divideSecond: job2,
                    etc
                }
                api.post(`job/update/search`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('수정 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { title, phone, startDate, endDate, job, job2, team, safe, etc } = this.state
        return (
        <>
            <div className="content">
                <h2>구직신청 상세</h2>
                <div className="location">
                    <span>인력관리</span>
                    <span>구직관리</span>
                    <span>구직신청상세</span>
                </div>
                <div className="detail">
                    <table>
                        <caption>구직신청 상세</caption>
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="title" 
                                        value={title} 
                                        onChange={this.handleChange} 
                                        placeholder="이름" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="phone" 
                                        value={phone} 
                                        onChange={this.handleChange} 
                                        placeholder="연락처" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>작업 가능 날짜 - 시작</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="startDate" 
                                        value={startDate} 
                                        onChange={this.handleDate} 
                                        placeholder="숫자만 입력" 
                                        className='size1'
                                        maxLength={10}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>작업 가능 날짜 - 종료</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="endDate" 
                                        value={endDate} 
                                        onChange={this.handleDate} 
                                        placeholder="숫자만 입력" 
                                        className='size1'
                                        maxLength={10}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>직종</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="job" 
                                        value={job} 
                                        onChange={this.handleChange} 
                                        placeholder="직종" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>직종 상세</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="job2" 
                                        value={job2} 
                                        onChange={this.handleChange} 
                                        placeholder="직종 상세" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>개인/팀 여부</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="team" 
                                        value={team} 
                                        onChange={this.handleChange} 
                                        placeholder="개인 / 팀(인원수)" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>기초안전교육</th>
                                <td>
                                    <input 
                                        name='safe'
                                        checked={safe}
                                        type="checkbox" 
                                        onChange={this.handleSafe}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td>
                                    <textarea 
                                        name='etc'
                                        value={etc} 
                                        onChange={this.handleChange}  
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">수정하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(JobsearchDetail);