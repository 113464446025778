import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import DaumPost from '../../components/DaumPost'

class EquipRentalDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            title: '',
            divideFirst: '', 
            divideSecond: '', 
            address: '', 
            startDate: '', 
            endDate: '', 
            userName: '', 
            phone: '',
            src: '',
            file: {}
        })
    }

    componentDidMount() {
        console.log(this.props.match.params.idx)
        this.getDetail()
    }

    getDetail = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`equip/detail/${this.props.match.params.idx}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        const res = result.data.data.result
                        this.setState({
                            title: res.construction_title,
                            divideFirst: res.construction_divide_first,
                            divideSecond: res.construction_divide_second,
                            address: res.construction_location,
                            addressDetail: res.construction_location_detail,
                            startDate: moment(res.construction_period_start).format('YYYY-MM-DD'),
                            endDate: moment(res.construction_period_end).format('YYYY-MM-DD'),
                            userName: res.user_name,
                            phone: res.construction_user_phone,
                            etc: res.construction_etc,
                            src: String(res.construction_img).slice(0, 4) === 'file' 
                            ? 'https://momobuild.s3.ap-northeast-2.amazonaws.com/' + res.construction_img
                            : res.construction_img,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSafe = e => this.setState({safe: !this.state.safe})
    
    handleDate = e => {
        let val = e.target.value
        val = val.replace('--', '-')
        if (val.match(/^\d{5}$/) !== null) {
            val = String(val).slice(0, 4) + '-' + String(val).slice(4, 5)
        } else if (val.match(/^\d{4}\-\d{3}$/) !== null) {
            val = String(val).slice(0, 7) + '-' + String(val).slice(7, 8)
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
  
            reader.onload = e => {
              this.preview.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
  
            this.setState({file: e.target.files[0]})
          }
    }

    toggleIsOpen = () => this.setState({isOpen: !this.state.isOpen})

    handleAddress = data => {
        this.setState({
            isOpen: false,
            address: data.address
        })
    }

    onSubmit = async () => {
        const { title, divideFirst, divideSecond, src, address, addressDetail, startDate, endDate, phone, etc, file } = this.state
        if (title.trim().length === 0) {
            alert('장비종류를 입력해주세요')
            return false
        } else if (divideSecond.trim().length === 0) {
            alert('연식을 입력해주세요')
            return false
        } else if (address.trim().length === 0) {
            alert('시/구를 입력해주세요')
            return false
        } else if (addressDetail.trim().length === 0) {
            alert('구/군을 입력해주세요')
            return false
        } else if (!moment(startDate).isValid()) {
            alert('시작일을 양식에 맞게 입력해주세요')
            return false
        } else if (!moment(endDate).isValid()) {
            alert('종료일을 양식에 맞게 입력해주세요')
            return false
        } else if (phone.trim().length === 0) {
            alert('연락처을 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                let formData = new FormData()
                formData.append('constructionIdx', this.props.match.params.idx)
                formData.append('title', title)
                formData.append('divideFirst', divideFirst)
                formData.append('divideSecond', divideSecond)
                formData.append('location', address)
                formData.append('locationDetail', addressDetail)
                formData.append('periodStart', startDate)
                formData.append('periodEnd', endDate)
                formData.append('phone', phone)
                formData.append('etc', etc)
                if (file.size !== undefined) {
                    formData.append('constructionImage', file)
                } else {
                    let str = src
                    if (str === undefined || str === null || str === 'undefined' || str === 'null') {
                        str = ''
                    } else if (String(src).startsWith('https://momobuild.s3.ap-northeast-2.amazonaws.com/')) {
                        str = String(src).replace('https://momobuild.s3.ap-northeast-2.amazonaws.com/', '')
                    }
                    formData.append('originalFile', str)
                }
                api.post(`equip/update/rental`, formData, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('수정 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { title, divideFirst, divideSecond, src, address, addressDetail, startDate, endDate, userName, phone, etc } = this.state
        return (
        <>
            <div className="content">
                <h2>장비임대상세</h2>
                <div className="location">
                    <span>장비관리</span>
                    <span>장비임대</span>
                    <span>장비임대상세</span>
                </div>
                <div className="detail">
                    <table>
                        <caption>장비임대상세</caption>
                        <tbody>
                            <tr>
                                <th>장비종류</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="title" 
                                        value={title} 
                                        onChange={this.handleChange} 
                                        placeholder="장비종류" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>연식</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="divideSecond" 
                                        value={divideSecond} 
                                        onChange={this.handleChange} 
                                        placeholder="연식 (숫자만 입력)" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>장비사진</th>
                                <td>
                                    <div className="img_box circle">
                                        <input 
                                            type="file" 
                                            style={{display: "none" }}
                                            name="file" 
                                            accept="image/*" 
                                            onChange={this.handleFile}
                                            ref={ref => this.fileInput = ref}
                                        /> 
                                        <img 
                                            src={(src === undefined || src === null || src === '') 
                                                ? require("../../img/equip.png") 
                                                : src} 
                                            ref={ref => this.preview = ref} 
                                            alt="장비사진"
                                            style={{width: 100, height: 100, borderRadius: '50%', border: '1px solid #c4c4c4'}}
                                        />
                                        {/* <button className="btn_delete">삭제</button> */}
                                        <button 
                                            className="btn btn4 ml10" 
                                            onClick={() => this.fileInput.click()}
                                            style={{verticalAlign: 'top'}}
                                        >
                                            파일찾기
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>지역(시/구)</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="address" 
                                        value={address} 
                                        onChange={this.handleChange} 
                                        placeholder="시/구" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>지역(구/군)</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="addressDetail" 
                                        value={addressDetail} 
                                        onChange={this.handleChange} 
                                        placeholder="구/군" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                            <tr className="start_date">
                                <th>시작일</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="startDate" 
                                        value={startDate} 
                                        onChange={this.handleDate} 
                                        placeholder="숫자만 입력" 
                                        className='size1'
                                        maxLength={10}
                                    />
                                </td>
                            </tr>
                            <tr className="end_date">
                                <th>종료일</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="endDate" 
                                        value={endDate} 
                                        onChange={this.handleDate} 
                                        placeholder="숫자만 입력" 
                                        className='size1'
                                        maxLength={10}
                                    />
                                </td>
                            </tr>
                            {/* <tr>
                                <th>작업일수</th>
                                <td><input type="text" className="size1" /></td>
                            </tr> */}
                            <tr>
                                <th>신청자</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="userName" 
                                        value={userName} 
                                        onChange={this.handleChange} 
                                        placeholder="신청자(수정불가)" 
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="phone" 
                                        value={phone} 
                                        onChange={this.handleChange} 
                                        placeholder="연락처" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td>
                                    <textarea 
                                        name='etc'
                                        value={etc} 
                                        onChange={this.handleChange}  
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">수정하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
                <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleIsOpen}>
                    <DaumPost handleAddress={this.handleAddress} />
                </Modal>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(EquipRentalDetail);