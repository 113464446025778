import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';
import { Player } from 'video-react'


class PostDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            board: {},
            reply: [],
            additional: '',
            additionalDivision: '',
            extension: ''
        })
    }

    componentDidMount() {
        console.log(this.props.match.params.idx)
        this.getDetail()
    }

    getDetail = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`board/detail/${this.props.match.params.idx}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        // 이미지 / 비디오 구분용
                        let flag = false
                        const imageExtension = [
                            '.bmp',
                            '.rle',
                            '.dib',
                            '.jpg',
                            '.jpeg',
                            '.gif',
                            '.png',
                            '.tif',
                            '.tiff',
                            '.raw',
                            '.svg',
                            '.webp'
                        ]
                        const res = result.data.data
                        let additional = ''
                        let additionalDivision = ''
                        res.result.timestamp_create = moment(res.result.timestamp_create).format('YYYY-MM-DD hh:mm')
                        if (res.reply) {
                            res.reply.map(val => {
                                val.timestamp_create = moment(val.timestamp_create).format('YYYY-MM-DD hh:mm')
                            })
                        }
                        if (
                            String(res.result.issue_image) !== undefined &&
                            String(res.result.issue_image) !== null &&
                            String(res.result.issue_image) !== ''
                        ) {
                            const loc = String(res.result.issue_image).toLowerCase()
                            imageExtension.map(inner => {
                                if (String(loc).includes(inner)) {
                                    flag = true
                                }
                            })
                        }
                        if (
                            res.result.issue_additional !== undefined &&
                            res.result.issue_additional !== null &&
                            String(res.result.issue_additional) !== ''
                        ) {
                            const loc = String(res.result.issue_additional).toLowerCase()
                            imageExtension.map(inner => {
                                if (String(loc).includes(inner)) {
                                    additional = res.result.issue_additional
                                    additionalDivision = 'image'
                                }
                            })
        
                            if (additionalDivision === '') {
                                additional = res.result.issue_additional
                                additionalDivision = 'video'
                            }
                        }
                        this.setState({
                            additional,
                            additionalDivision,
                            extension: (res.result.issue_image && flag) ? 'image' : 'video',
                            board: res.result,
                            reply: res.reply,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { board, reply, additional, additionalDivision, extension } = this.state
        return (
            <>
            <div className="content">
                <h2>게시글 상세</h2>
                <div className="location">
                    <span>이슈관리</span>
                    <span>게시글 관리</span>
                    <span>게시글 상세</span>
                </div>
                <div className="post_detail">
                    <div className="post_box">
                        <div className="img">
                            <img src={require("../../img/member_img.png")} alt="회원 이미지" />
                        </div>
                        <p className="post_title">{board.issue_title}</p>
                        <div className="post_info">
                            <div className="info1">
                                <ul>
                                    <li>{board.nickname}</li>
                                    <li>{board.timestamp_create}</li>
                                </ul>
                            </div>
                            <ul className="info2">
                                <li>조회수 {board.view_count}</li>
                                <li>댓글 {reply ? reply.length : 0}</li>
                            </ul>
                        </div>
                        <div className="post_content">
                            {
                                board.issue_additional !== '' && additionalDivision === 'image'
                                ? (
                                    <div className="img">
                                        <img src={require("../../img/member_img.png")} alt="회원 이미지" />
                                    </div>
                                ) : board.issue_additional !== '' && additionalDivision === 'video' 
                                ? (
                                    <Player
                                        playsInline
                                        fluid={false}
                                        // poster="/assets/poster.png"
                                        src={board.issue_additional}
                                        width={'100%'}
                                        height={600}
                                    />
                                ) : null
                            }
                            {
                                board.issue_image !== '' && extension === 'image'
                                ? (
                                    <div className="img">
                                        <img src={'https://momobuild.s3.ap-northeast-2.amazonaws.com/' + board.issue_image} alt="회원 이미지" />
                                    </div>
                                ) : board.issue_image !== '' && extension === 'video' 
                                ? (
                                    <Player
                                        playsInline
                                        fluid={false}
                                        // poster="/assets/poster.png"
                                        src={'https://momobuild.s3.ap-northeast-2.amazonaws.com/' + board.issue_image}
                                        width={'100%'}
                                        height={600}
                                    />
                                ) : null
                            }
                            {board.issue_content}
                        </div>
                    </div>
                    <div className="comment_box">
                        <span>댓글 {reply ? reply.length : 0}</span>
                        <ul>
                            {
                                reply && reply.map(val => {
                                    return (
                                        <li>
                                            {val.reply_content}
                                            <div className="comment_info">
                                                <span>{val.user_name}({val.code_blockchain})</span>
                                                <span>{val.timestamp_create}</span>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(PostDetail);