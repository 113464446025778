import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class EstimateList extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            pageNo: 1,
            list: [],
            paging: {},
            word: '',
            search: false,
            redirect: null
        }
    }

    async componentDidMount() {
        this.getList()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.word.length === 0 && this.state.search) {
                this.setState({
                    pageNo: 1,
                    search: false,
                })
                this.getList()
            }
        })
    }

    handlePageNo = val => {
        this.setState({
            pageNo: val
        }, () => {
            if (this.state.search) {
                this.onSearch()
            } else {
                this.getList()
            }
        })
    }

    handleAllCheck = e => {
        let list = this.state.list
        list.forEach(val => val.isChecked = e.target.checked)
        this.setState({list}, () => this.forceUpdate())
    }

    checkList = idx => {
        let list = this.state.list
        list[idx].isChecked = !list[idx].isChecked
        this.setState({list}, () => this.forceUpdate())
    }

    checkEnter = e => {
        if (e.code === 'Enter') {
            this.onSearch()
        }
    }

    onDelete = async () => {
        const { list } = this.state
        let arr = list.filter(val => val.isChecked)

        if(arr.length === 0) {
            alert('삭제할 항목을 체크해주세요')
            return false
        }
        arr = arr.map(val => {return val.site_rating_idx})

        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                const body = {
                    idx: String(arr)
                }
                api.post(`rating/delete`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('삭제 성공')
                        this.getList()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })

    }

    checkPrev = () => {
        const {pageNo, search} = this.state
        if (pageNo > 1) {
            this.setState({
                pageNo: pageNo - 1
            }, () => {
                if (search) {
                    this.onSearch()
                } else {
                    this.getList()
                }
            })
        }
    }

    checkNext = () => {
        const {pageNo, search, paging} = this.state
        if (pageNo < paging.finalPageNo) {
            this.setState({
                pageNo: pageNo + 1
            }, () => {
                if (search) {
                    this.onSearch()
                } else {
                    this.getList()
                }
            })
        }
    }

    getList = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`rating/list/${this.state.pageNo}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        let list = result.data.data.result
                        list = list.map(val => {
                                val.isChecked = false
                            val.timestamp_create = moment(val.timestamp_create).format('YYYY-MM-DD')
                            val.average = Number(val.average).toFixed(1)
                            return val
                        })
                        this.setState({
                            paging: result.data.data.paging,
                            list: list,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    onSearch = async () => {
        if (this.state.word.trim().length <= 0) {
            alert('검색어를 입력해주세요.')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`rating/search/${this.state.word}/${this.state.pageNo}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        if (result.data.data.result.length > 0) {                            
                            let list = result.data.data.result
                            list = list.map(val => {
                                val.isChecked = false
                                val.timestamp_create = moment(val.timestamp_create).format('YYYY-MM-DD')
                                val.average = Number(val.average).toFixed(1)
                                return val
                            })
                            this.setState({
                                paging: result.data.data.paging,
                                list: list,
                                isLoading: false,
                                search: true
                            })
                        } else {
                            alert('검색 결과가 없습니다.')
                            if (this.state.search) {
                                this.setState({
                                    pageNo: 1,
                                    search: false
                                }, () => this.getList())
                            }
                            return
                        }
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { paging, pageNo, redirect } = this.state
        
        const handlePageNo = this.handlePageNo

        function getPaging() {
            let arr = []
            if (paging) {
                for(let i = paging.firstPageNo; i <= paging.finalPageNo; i++) {
                    arr.push(
                        <li className={pageNo === i ? "on" : null}>
                            <button onClick={() => handlePageNo(i)} name="pageNo" value={i}>
                                {i}
                            </button>
                        </li>
                    )
                }
            }
            return arr
        }

        return (
        <>
            <div className="content">
                <h2>평가리스트</h2>
                <div className="location">
                    <span>평가관리</span>
                    <span>평가리스트</span>
                </div>
                <div className="search">
                    <fieldset>
                        <label htmlFor="input_search">검색</label>
                        <input 
                            type="text" 
                            placeholder="검색어를 입력하세요." 
                            name='word' 
                            id="input_search" 
                            value={this.state.word} 
                            onChange={this.handleChange}
                            onKeyDown={this.checkEnter}
                        ></input>
                        <button type="button" onClick={this.onSearch}>검색</button>
                    </fieldset>
                </div>
                <div className="list">
                    <div className="scroll">
                        <table style={{minWidth: "1500px"}}>
                            <caption>평가리스트</caption>
                            <thead>
                                <tr>
                                    <th className="chk">
                                        <p className="chk1">
                                            <input type="checkbox" id="chk0" onClick={this.handleAllCheck} />
                                            <label htmlFor="chk0">체크박스</label>
                                        </p>
                                    </th>
                                    <th className="no">No.</th>
                                    <th className="tal">작성자</th>
                                    <th className="tal">건설사</th>
                                    <th className="tal">현장명</th>
                                    <th className="tal">업무유형</th>
                                    <th className="tal">업무경력</th>
                                    <th className="tal">현장평가</th>
                                    <th className="tal">종합 한줄평</th>
                                    <th>평가날짜</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.list && this.state.list.map((val, idx) => {
                                        return (
                                            <tr>
                                                <td className="chk">
                                                    <p className="chk1">
                                                        <input 
                                                            type="checkbox"  
                                                            id={`chk${val.site_rating_idx}`} 
                                                            checked={val.isChecked} 
                                                            onChange={() => this.checkList(idx)} 
                                                        />
                                                        <label htmlFor={`chk${val.site_rating_idx}`}>체크박스</label>
                                                    </p>
                                                </td>
                                                <td className="no">{val.site_rating_idx}</td>
                                                <td className="no">{val.user_idx}</td>
                                                <td className="tal">{val.company_name}</td>
                                                <td className="tal">{val.site_name}</td>
                                                <td className="tal">{val.user_divide_first}</td>
                                                <td className="tal">{val.user_divide_second}년</td>
                                                <td className="tal">{val.average}</td>
                                                <td className="tal">{val.site_rating_comment}</td>
                                                <td>{val.timestamp_create}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="btn_wrap">
                        <button className="btn btn1" onClick={this.onDelete}>삭제</button>
                        {/* 체크하면 버튼 활성화
                        <button className="btn btn2">삭제</button>
                        */}
                    </div>
                    <div className="paging">
                        <ul className="clearfix">
                            <li><button onClick={this.checkPrev}>이전 페이지</button></li>
                            {getPaging()}
                            <li><button onClick={this.checkNext}>다음 페이지</button></li>
                        </ul>
                    </div>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(EstimateList)
