import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import DaumPost from '../../components/DaumPost'

class JobofferDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            workIdx: '',
            companyName: '',
            title: '',
            userName: '',
            phone: '',
            address: '',
            startDate: '',
            endDate: '',
            job: '',
            rest: '',
            payment: '',
            etc: '',
            src: '',
            file: {}
        })
    }

    componentDidMount() {
        console.log(this.props.match.params.idx)
        this.getDetail()
    }

    getDetail = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`job/detail/${this.props.match.params.idx}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        const res = result.data.data.result
                        this.setState({
                            companyName: res.work_constructor,
                            title: res.work_title,
                            address: res.work_location,
                            startDate: moment(res.work_period_start).format('YYYY-MM-DD'),
                            endDate: moment(res.work_period_end).format('YYYY-MM-DD'),
                            userName: res.user_name,
                            phone: res.work_user_phone,
                            job: res.work_divide_first,
                            rest: res.work_rest,
                            payment: res.work_payment,
                            etc: res.work_etc,
                            src: String(res.work_image).slice(0, 4) === 'file' 
                            ? 'https://momobuild.s3.ap-northeast-2.amazonaws.com/' + res.work_image
                            : res.work_image,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSafe = e => this.setState({safe: !this.state.safe})
    
    handleDate = e => {
        let val = e.target.value
        val = val.replace('--', '-')
        if (val.match(/^\d{5}$/) !== null) {
            val = String(val).slice(0, 4) + '-' + String(val).slice(4, 5)
        } else if (val.match(/^\d{4}\-\d{3}$/) !== null) {
            val = String(val).slice(0, 7) + '-' + String(val).slice(7, 8)
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
  
            reader.onload = e => {
              this.preview.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
  
            this.setState({file: e.target.files[0]})
          }
    }

    toggleIsOpen = () => this.setState({isOpen: !this.state.isOpen})

    handleAddress = data => {
        this.setState({
            isOpen: false,
            address: data.address
        })
    }

    onSubmit = async () => {
        const { companyName, title, address, startDate, endDate, rest, userName, phone, job, payment, etc, src, file } = this.state
        if (companyName.trim().length === 0) {
            alert('건설사명을 입력해주세요')
            return false
        } else if (title.trim().length === 0) {
            alert('현장명을 입력해주세요')
            return false
        } else if (address.trim().length === 0) {
            alert('주소를 입력해주세요')
            return false
        } else if (!moment(startDate).isValid()) {
            alert('시작일을 양식에 맞게 입력해주세요')
            return false
        } else if (!moment(endDate).isValid()) {
            alert('종료일을 양식에 맞게 입력해주세요')
            return false
        } else if (phone.trim().length === 0) {
            alert('연락처을 입력해주세요')
            return false
        } else if (job.trim().length === 0) {
            alert('직종을 입력해주세요')
            return false
        } else if (payment.trim().length === 0) {
            alert('단가를 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                let formData = new FormData()
                formData.append('workIdx', this.props.match.params.idx)
                formData.append('workTitle', title)
                formData.append('constructor', companyName)
                formData.append('siteAddress', address)
                formData.append('periodStart', startDate)
                formData.append('periodEnd', endDate)
                formData.append('rest', rest)
                formData.append('phone', phone)
                formData.append('divideFirst', job)
                formData.append('payment', payment)
                formData.append('etc', etc)
                if (file.size !== undefined) {
                    formData.append('jobImage', file)
                } else {
                    let str = src
                    if (str === undefined || str === null || str === 'undefined' || str === 'null') {
                        str = ''
                    } else if (String(src).startsWith('https://momobuild.s3.ap-northeast-2.amazonaws.com/')) {
                        str = String(src).replace('https://momobuild.s3.ap-northeast-2.amazonaws.com/', '')
                    }
                    formData.append('originalFile', str)
                }
                api.post(`job/update/offer`, formData, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('수정 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { companyName, title, address, startDate, endDate, rest, userName, phone, job, payment, etc, src } = this.state
        return (
        <>
            <div className="content">
                <h2>구인신청 상세</h2>
                <div className="location">
                    <span>인력관리</span>
                    <span>구인관리</span>
                    <span>구인신청상세</span>
                </div>
                <div className="detail">
                    <table>
                        <caption>구인신청 상세</caption>
                        <tbody>
                            <tr>
                                <th>건설사 명</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="companyName" 
                                        value={companyName} 
                                        onChange={this.handleChange} 
                                        placeholder="건설사 명" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>현장 명</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="title" 
                                        value={title} 
                                        onChange={this.handleChange} 
                                        placeholder="현장 명" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>현장 위치</th>
                                <td>
                                    <div className="address">
                                        <input 
                                            type="text" 
                                            name="address" 
                                            value={address} 
                                            onChange={this.handleChange} 
                                            placeholder="현장 위치" 
                                        />
                                        <button onClick={this.toggleIsOpen} className="btn btn2">주소찾기</button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="start_date">
                                <th>시작일</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="startDate" 
                                        value={startDate} 
                                        onChange={this.handleDate} 
                                        placeholder="숫자만 입력" 
                                        className='size1'
                                        maxLength={10}
                                    />
                                </td>
                            </tr>
                            <tr className="end_date">
                                <th>종료일</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="endDate" 
                                        value={endDate} 
                                        onChange={this.handleDate} 
                                        placeholder="숫자만 입력" 
                                        className='size1'
                                        maxLength={10}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>휴무일</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="rest" 
                                        value={rest} 
                                        onChange={this.handleChange} 
                                        placeholder="휴무일" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>신청자</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="userName" 
                                        value={userName} 
                                        onChange={this.handleChange} 
                                        placeholder="신청자(수정불가)" 
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="phone" 
                                        value={phone} 
                                        onChange={this.handleChange} 
                                        placeholder="연락처" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>직종</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="job" 
                                        value={job} 
                                        onChange={this.handleChange} 
                                        placeholder="직종" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>단가</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="payment" 
                                        value={payment} 
                                        onChange={this.handleChange} 
                                        placeholder="단가(숫자만 입력)" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>기타</th>
                                <td>
                                    <textarea 
                                        name='etc'
                                        value={etc} 
                                        onChange={this.handleChange}  
                                    />
                                </td>
                            </tr>
                            {/* <tr>
                                <th>현장사진</th>
                                <td>
                                    <div className="img_box">
                                        <input 
                                            type="file" 
                                            style={{display: "none" }}
                                            name="file" 
                                            accept="image/*" 
                                            onChange={this.handleFile}
                                            ref={ref => this.fileInput = ref}
                                        /> 
                                        <img 
                                            src={(src === undefined || src === null || src === '') 
                                                ? require("../../img/place_img.png") 
                                                : src} 
                                            ref={ref => this.preview = ref} 
                                            alt="현장사진"
                                        />
                                        <button 
                                            className="btn btn4 ml10" 
                                            onClick={() => this.fileInput.click()}
                                            style={{verticalAlign: 'top'}}
                                        >
                                            파일찾기
                                        </button>
                                    </div>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">수정하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
                <Modal isOpen={this.state.isOpen} onRequestClose={this.toggleIsOpen}>
                    <DaumPost handleAddress={this.handleAddress} />
                </Modal>
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(JobofferDetail);