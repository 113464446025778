import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class JobofferMangement extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            pageNo: 1,
            list: [],
            paging: {},
            word: '',
            search: false,
            redirect: null
        }
    }

    async componentDidMount() {
        this.getList()
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            if (this.state.word.length === 0 && this.state.search) {
                this.setState({
                    pageNo: 1,
                    search: false,
                })
                this.getList()
            }
        })
    }

    handleAllCheck = e => {
        let list = this.state.list
        list.forEach(val => val.isChecked = e.target.checked)
        this.setState({list}, () => this.forceUpdate())
    }

    checkList = idx => {
        let list = this.state.list
        list[idx].isChecked = !list[idx].isChecked
        this.setState({list}, () => this.forceUpdate())
    }

    checkEnter = e => {
        if (e.code === 'Enter') {
            this.onSearch()
        }
    }

    onDelete = async () => {
        const { list } = this.state
        let arr = list.filter(val => val.isChecked)

        if(arr.length === 0) {
            alert('삭제할 항목을 체크해주세요')
            return false
        }
        arr = arr.map(val => {return val.work_idx})

        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                const body = {
                    idx: String(arr)
                }
                api.post(`job/delete`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('삭제 성공')
                        this.getList()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })

    }

    onAllow = async () => {
        const { list } = this.state
        let arr = list.filter(val => val.isChecked)

        if(arr.length === 0) {
            alert('허가할 항목을 체크해주세요')
            return false
        }
        arr = arr.map(val => {return val.work_idx})

        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                const body = {
                    idx: String(arr)
                }
                api.post(`job/allow`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('허가 성공')
                        this.getList()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })

    }

    handlePageNo = val => {
        this.setState({
            pageNo: val
        }, () => {
            if (this.state.search) {
                this.onSearch()
            } else {
                this.getList()
            }
        })
    }

    checkPrev = () => {
        const {pageNo, search} = this.state
        if (pageNo > 1) {
            this.setState({
                pageNo: pageNo - 1
            }, () => {
                if (search) {
                    this.onSearch()
                } else {
                    this.getList()
                }
            })
        }
    }

    checkNext = () => {
        const {pageNo, search, paging} = this.state
        if (pageNo < paging.finalPageNo) {
            this.setState({
                pageNo: pageNo + 1
            }, () => {
                if (search) {
                    this.onSearch()
                } else {
                    this.getList()
                }
            })
        }
    }

    getList = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`job/list/0/${this.state.pageNo}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        let list = result.data.data.result
                        list = list.map(val => {
                                val.isChecked = false
                            val.work_period_start = moment(val.work_period_start).format('YYYY-MM-DD')
                            val.work_period_end = moment(val.work_period_end).format('YYYY-MM-DD')
                            val.work_payment = String(val.work_payment).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            return val
                        })
                        this.setState({
                            paging: result.data.data.paging,
                            list: list,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    onSearch = async () => {
        if (this.state.word.trim().length <= 0) {
            alert('검색어를 입력해주세요.')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`job/search/0/${this.state.word}/${this.state.pageNo}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        if (result.data.data.result.length > 0) {                            
                            let list = result.data.data.result
                            list = list.map(val => {
                                val.isChecked = false
                                val.work_period_start = moment(val.work_period_start).format('YYYY-MM-DD')
                                val.work_period_end = moment(val.work_period_end).format('YYYY-MM-DD')
                                val.work_payment = String(val.work_payment).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                return val
                            })
                            this.setState({
                                paging: result.data.data.paging,
                                list: list,
                                isLoading: false,
                                search: true
                            })
                        } else {
                            alert('검색 결과가 없습니다.')
                            if (this.state.search) {
                                this.setState({
                                    pageNo: 1,
                                    search: false
                                }, () => this.getList())
                            }
                            return
                        }
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { paging, pageNo, redirect } = this.state
        
        const handlePageNo = this.handlePageNo

        function getPaging() {
            let arr = []
            if (paging) {
                for(let i = paging.firstPageNo; i <= paging.finalPageNo; i++) {
                    arr.push(
                        <li className={pageNo === i ? "on" : null}>
                            <button onClick={() => handlePageNo(i)} name="pageNo" value={i}>
                                {i}
                            </button>
                        </li>
                    )
                }
            }
            return arr
        }

        return (
        <>
            <div className="content">
                <h2>구인신청 내역</h2>
                <div className="location">
                    <span>인력관리</span>
                    <span>구인관리</span>
                </div>
                <div className="search">
                    <fieldset>
                        <label htmlFor="input_search">검색</label>
                        <input 
                            type="text" 
                            placeholder="검색어를 입력하세요." 
                            name='word' 
                            id="input_search" 
                            value={this.state.word} 
                            onChange={this.handleChange}
                            onKeyDown={this.checkEnter}
                        ></input>
                        <button type="button" onClick={this.onSearch}>검색</button>
                    </fieldset>
                </div>
                <div className="list click">
                    <div className="scroll">
                        <table style={{minWidth: "1830px"}}>
                            <caption>구인신청 내역</caption>
                            <thead>
                                <tr>
                                    <th className="chk">
                                        <p className="chk1">
                                            <input type="checkbox" id="chk0" onClick={this.handleAllCheck} />
                                            <label htmlFor="chk0">체크박스</label>
                                        </p>
                                    </th>
                                    <th className="no">No.</th>
                                    <th className="tal">상태</th>
                                    <th className="tal">건설사 명</th>
                                    <th className="tal">현장명</th>
                                    <th className="tal">위치</th>
                                    <th>작업일</th>
                                    {/* <th>휴무일</th> */}
                                    {/* <th>신청자</th> */}
                                    <th>연락처</th>
                                    <th className="tal">직종</th>
                                    <th className="tal">단가</th>
                                    <th className="tal">기타사항</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.list && this.state.list.map((val, idx) => {
                                        return (
                                            <tr>
                                                <td className="no">
                                                    <p className="chk1">
                                                        <input 
                                                            type="checkbox" 
                                                            id={`chk${val.work_idx}`} 
                                                            checked={val.isChecked} 
                                                            onChange={() => this.checkList(idx)} 
                                                        />
                                                        <label htmlFor={`chk${val.work_idx}`}>체크박스</label>
                                                    </p>
                                                </td>
                                                <td className="no" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_idx}
                                                </td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_status === 0 ? '허가' : val.work_status === 1 ? '비허가' : '삭제'
                                                }</td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_constructor}
                                                </td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_title}
                                                </td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_location}
                                                </td>
                                                <td onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_period_start} ~ {val.work_period_end}
                                                </td>
                                                {/* <td>토, 일</td> */}
                                                {/* <td>김모모</td> */}
                                                <td onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_user_phone}
                                                </td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_divide_first}
                                                </td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_payment}
                                                </td>
                                                <td className="tal" onClick={() => this.props.history.push(`/humanManagement/joboffer/detail/${val.work_idx}`)}>
                                                    {val.work_etc}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="btn_wrap">
                        <button className="btn btn1" onClick={this.onDelete}>삭제</button>
                        <button className="btn btn3 fr" onClick={this.onAllow}>허가</button>
                    </div>
                    <div className="paging">
                        <ul className="clearfix">
                            <li><button onClick={this.checkPrev}>이전 페이지</button></li>
                            {getPaging()}
                            <li><button onClick={this.checkNext}>다음 페이지</button></li>
                        </ul>
                    </div>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(JobofferMangement)