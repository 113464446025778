import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import { connect } from 'react-redux';

class TypeAdd extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            name: '',
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = async () => {
        const { name } = this.state
        if (name.trim().length === 0) {
            alert('이름을 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                const body = {
					name
				}
                api.post(`labor/typeList/add`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('추가 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { name } = this.state
        return (
        <>
            <div className="content">
                <h2>타입추가</h2>
                <div className="location">
                    <span>노무관리</span>
                    <span>타입관리</span>
                    <span>타입추가</span>
                </div>            
                <div className="detail">
                    <table>
                        <caption>구직신청 상세</caption>
                        <tbody>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        value={name} 
                                        onChange={this.handleChange} 
                                        placeholder="이름" 
										className='size1'
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">추가하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(TypeAdd);