import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT } from '../Redux/userSlice';

function Header(props) {
    const { user, isLogin, Result, Type } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const logout = () => {
        if (isLogin === true) {
        //   history.push('/User');
            dispatch(LOGOUT());
            history.push("/");
        }
    }
    return (
        <>
        <div className="top">
            <h1 className="logo"><Link to={'/'}><img src={require("../img/logo.png")} alt="건설모모 로고" /></Link></h1>
            {
                isLogin && 
                <>
                    <ul className="menu">
                        <li className="id"><span>관리자(admin)</span></li>
                        <li className="btn_logout" onClick={logout}><button>로그아웃</button></li>
                    </ul>
                </>
            }
        </div>
        </>
    );
}
export default Header;