import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'auth',
  initialState: {
    user: [],
    isLogin: false,
    Type: '',
    
  },
  reducers: {
    LOGIN: (state, action) => {
      state.user = action.payload;
      state.isLogin = true;
    },
    LOGINTYPE: (state, action) => {
      state.Type = action.payload;
    },
    LOGOUT: state => {
      state.Type = '';
      state.user = [];
      state.isLogin = false;
    },
  },
});

export const selectUser = state => state.user;
export const { LOGIN, LOGOUT, LOGINTYPE } = userSlice.actions;
export default userSlice.reducer;
