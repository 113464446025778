import React from 'react';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';

var regex = /^[0-9,]*$/

class PushSend extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
			type: '',
			idx: '',
            title: '',
            content: '',
            typeList: [],
        })
    }

    handleChange = e => {
		if (e.target.name === 'idx') {
			if(!regex.test(String(e.target.value))) {
				return false
			}
		}
		this.setState({
			[e.target.name]: e.target.value
		})
    }

    onSubmit = async () => {
        const { type, idx, title, content } = this.state
        if (type.trim().length === 0) {
            alert('발송 타입을 선택해주세요')
            return false
        } else if (type === 'each' && idx.trim() === '') {
			alert('유저 번호를 입력해주세요')
			return false
		} else if (title.trim().length === 0) {
            alert('제목을 입력해주세요')
            return false
        } else if (content.trim().length === 0) {
            alert('내용을 입력해주세요')
            return false
        }
		let arr = ''
		if (type === 'each') arr = idx.split(',').filter(n => n) // 배열형태로 만들고 중복되는 ',' 제거함
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                const body = {
					idx: arr.toString(),
					type,
					title,
					content
				}
                api.post(`push/send`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('발송 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { type, idx, title, content } = this.state
        return (
        <>
            <div className="content">
                <h2>알림관리</h2>
                <div className="location">
                    <span>알림관리</span>
                    <span>알림발송</span>
                </div>            
                <div className="detail">
                    <table>
                        <caption>알림발송</caption>
                        <tbody>
                            <tr>
                                <th>상담분류</th>
                                <td>
                                    <input 
                                        type="radio" 
                                        name="type" 
                                        value={'all'} 
                                        onChange={this.handleChange} 
                                        placeholder="타입" 
										id='radio1'
                                    />
									<label for="radio1">
										단체발송
									</label>
                                    <input 
                                        type="radio" 
                                        name="type" 
                                        value={'each'} 
                                        onChange={this.handleChange} 
                                        placeholder="타입" 
										id='radio2'
										style={{marginLeft: 20, marginTop: 10}}
                                    />
									<label for="radio2">
										개별발송
									</label>
                                </td>
                            </tr>
							{
								type === 'each' && 
								<tr>
									<th>유저번호</th>
									<td>
										<input 
											type="text" 
											name="idx" 
											value={idx} 
											onChange={this.handleChange} 
											placeholder="1,2,3 (공백 X)" 
										/>
									</td>
								</tr>
							}
                            <tr>
                                <th>제목</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="title" 
                                        value={title} 
                                        onChange={this.handleChange} 
                                        placeholder="제목" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <textarea 
                                        name='content'
                                        value={content} 
                                        onChange={this.handleChange}  
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">발송하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(PushSend);