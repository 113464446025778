import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Sidebar(props) {
    const [activeIndex, setActiveIndex] = useState(null);
    const [clickedIdx, setClickedIdx] = useState();

    const toggle = i => {
        if (activeIndex == i){
            return setActiveIndex(null)
        }
        setActiveIndex(i)
    }

    return (
        <>
        <div className="sidebar">
            <ul className="depth1">
            {data.map((item, i) => {
                const active = i == activeIndex ? 'active' : '';
                return (
                <li key={i} onClick={() => toggle(i)}
                className={
                    activeIndex == i ? 'show' : ''
                }>
                    <button>{item.depth1}</button>
                    <ul className="depth2">
                        {item.depth2.map((subMenu, i) => (
                        <li key={i}><Link to={item.link[i]}>{subMenu}</Link></li>
                        ))}
                    </ul>
                </li>
            )})}
            </ul>
            
        </div>
        </>
    )

}

export default Sidebar;


let data = [
    {
        depth1: '회원관리',
        depth2: ['회원현황'],
        link: ['/member']
    },
    // {
    //     depth1: '평가관리',
    //     depth2: ['평가리스트'],
    //     link: ['/estimate']
    // },
    // {
    //     depth1: '건설사관리',
    //     depth2: ['건설사리스트','현장리스트'],
    //     link: ['/construction/list','/construction/placelist']
    // },
    {
        // depth1: '인력관리',
        // depth2: ['구인관리','구직관리'],
        // link: ['/humanManagement/joboffer','/humanManagement/jobsearch']
        depth1: '일자리관리',
        depth2: ['일자리관리', '댓글관리'],
        link: ['/humanManagement/joboffer', '/humanManagement/jobCommentManagement']
    },
    // {
    //     // depth1: '장비관리',
    //     // depth2: ['장비임대','장비임차'],
    //     // link: ['/equip/rental','/equip/lease'],
    //     depth1: '장비관리',
    //     depth2: ['장비관리','장비주목록'],
    //     link: ['/equip/list','/owner/list'],
    // },
    // {
    // //     depth1: '노무관리',
    // //     depth2: ['노무현황','타입관리'],
    // //     link: ['/labor/list','/labor/type']
    //     depth1: '노무관리',
    //     depth2: ['노무현황'],
    //     link: ['/labor/list']
    // },
    {
        depth1: '이슈관리',
        depth2: ['게시글관리','댓글관리'],
        link: ['/issue/postManagement','/issue/commentManagement']
    },
    {
        depth1: '약관관리',
        depth2: ['약관관리'],
        link: ['/terms/termManagement']
    },
    {
        depth1: '알림관리',
        depth2: ['알림발송'],
        link: ['/push/send']
    },
    {
        depth1: '배너관리',
        depth2: ['배너관리'],
        link: ['/banner/list']
    },
    {
        depth1: '신고관리',
        depth2: ['신고관리'],
        link: ['/report/list']
    }
]