import React, {useState} from "react";
import PropTypes from "prop-types";
import SelectUnstyled, {
  selectUnstyledClasses
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses
} from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import arrow5 from '../img/arrow5.png';
import arrow6 from '../img/arrow6.png';


const StyledButton = styled("button")(
  ({ theme }) => `
  width: 355px;
  height: 35px;
  padding: 6px 22px;
  background: #fff;
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  font-family: 'Noto Sans';
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
  color: #000;
  position: relative;

  &.${selectUnstyledClasses.focusVisible} {
    border: 1px solid #23C4BD;
  }

  &.${selectUnstyledClasses.expanded} {
    border: 1px solild #23C4BD;
    &::after {
        background: url(${arrow6})
    }
  }

  &::after {
    content: '';
    display: block;
    width: 14px;
    height: 8px; 
    background: url(${arrow5});
    position: absolute;
    top: 14px;
    right: 14px;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  width: 355px;
  background: #fff;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: 'Noto Sans';
  font-size: 14px;
  color: #000;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
    height: 35px;
    padding: 10px 21px;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    &:first-of-type {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    &:last-of-type {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #EDF9F8;
    color: #000;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #000;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #EDF9F8;
    color: #000;
  }
  `
);

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components
  };

  return <SelectUnstyled {...props} components={components} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType
  })
};

export default function UnstyledSelectsMultiple(props) {
  const [value, setValue] = useState(Number(props.value))
  console.log(props)
  const onChange = e => {
    setValue(e)
    props.handleType(e)
  }
  const { list } = props
  return (
      <CustomSelect value={value} onChange={onChange}>
        {
          list && list.map((val, idx) => {
            return (
              <StyledOption key={idx} value={val.type_idx}>{val.type_name}</StyledOption>
            )
          })
        }
      </CustomSelect> 
  );
}