import React from 'react';
import UnstyledSelectsMultiple from '../../components/Select';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';

class OwnerDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            name: '',
            phone: '',
            address: '',
            career: '',
            category: '',
            src: '',
            file: {},
            typeList: [],
        })
    }

    componentDidMount() {
        this.getDetail()
    }

    getDetail = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            // 체크리스트 정보
            // try {
            //     const config = {
            //         headers: {
            //           'Content-Type': 'application/json',
            //           'Access-Control-Allow-Origin': '*',
            //           'Access-Control-Allow-Headers': '*',
            //           'authorization': `Bearer ${this.props.token}`
            //         },
            //     };
            //     api.get(`owner/type/all`, config).then((result) => {
            //         console.log(result)
            //         if(result.data.success) {
            //             this.setState({
            //                 typeList: result.data.data.result
            //             })
            //         } else {
            //             alert(result.data.message)
            //         }
            //     })
            // } catch (err) {
            //     alert('서버와 통신에 실패');
            //     console.log('err', err);
            // }

            // 노무사 정보
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`owner/detail/${this.props.match.params.idx}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        const res = result.data.data.result
                        this.setState({
                            name: res.owner_name,
                            phone: res.owner_phone,
                            address: res.owner_address,
                            career: res.owner_career,
                            category: res.owner_category,
                            src: String(res.owner_image).slice(0, 4) === 'file' 
                            ? 'https://momobuild.s3.ap-northeast-2.amazonaws.com/' + res.owner_image
                            : res.owner_image,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false}) 
            }
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSafe = e => this.setState({safe: !this.state.safe})
    
    handleDate = e => {
        let val = e.target.value
        val = val.replace('--', '-')
        if (val.match(/^\d{5}$/) !== null) {
            val = String(val).slice(0, 4) + '-' + String(val).slice(4, 5)
        } else if (val.match(/^\d{4}\-\d{3}$/) !== null) {
            val = String(val).slice(0, 7) + '-' + String(val).slice(7, 8)
        }
        this.setState({
            [e.target.name]: val
        })
    }

    handleFile = e => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader()
  
            reader.onload = e => {
              this.preview.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
  
            this.setState({file: e.target.files[0]})
          }
    }

    handleType = type => {
        this.setState({type})
        console.log(type)
    }

    toggleIsOpen = () => this.setState({isOpen: !this.state.isOpen})

    handleAddress = data => {
        this.setState({
            isOpen: false,
            address: data.address
        })
    }

    onSubmit = async () => {
        const { category, name, phone, address, career, src, file } = this.state
        if (category.trim().length === 0) {
            alert('상담분류를 선택해주세요')
            return false
        } else if (name.trim().length === 0) {
            alert('이름을 입력해주세요')
            return false
        } else if (phone.trim().length === 0) {
            alert('연락처을 입력해주세요')
            return false
        } else if (String(career).trim().length === 0) {
            alert('연식를 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                let formData = new FormData()
                formData.append('ownerIdx', this.props.match.params.idx)
                formData.append('ownerName', name)
                formData.append('ownerPhone', phone)
                formData.append('ownerAddress', address)
                formData.append('ownerCareer', career)
                formData.append('ownerCategory', category)
                if (file.size !== undefined) {
                    formData.append('ownerImage', file)
                } else {
                    let str = src
                    if (str === undefined || str === null || str === 'undefined' || str === 'null') {
                        str = ''
                    } else if (String(src).startsWith('https://momobuild.s3.ap-northeast-2.amazonaws.com/')) {
                        str = String(src).replace('https://momobuild.s3.ap-northeast-2.amazonaws.com/', '')
                    }
                    formData.append('originalFile', str)
                }
                api.post(`owner/update`, formData, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('수정 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { category, name, phone, address, career, src } = this.state
        return (
        <>
            <div className="content">
                <h2>장비주상세</h2>
                <div className="location">
                    <span>장비관리</span>
                    <span>장비주목록</span>
                    <span>장비주상세</span>
                </div>            
                <div className="detail">
                    <table>
                        <caption>장비주상세</caption>
                        <tbody>
                            <tr>
                                <th>카테고리</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="category" 
                                        value={category} 
                                        onChange={this.handleChange} 
                                        placeholder="카테고리" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>이름</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        value={name} 
                                        onChange={this.handleChange} 
                                        placeholder="이름" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>연락처</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="phone" 
                                        value={phone} 
                                        onChange={this.handleChange} 
                                        placeholder="연락처" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>주소</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="address" 
                                        value={address} 
                                        onChange={this.handleChange} 
                                        placeholder="주소" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>사진</th>
                                <td>
                                    <input 
                                        type="file" 
                                        style={{display: "none" }}
                                        name="file" 
                                        accept="image/*" 
                                        onChange={this.handleFile}
                                        ref={ref => this.fileInput = ref}
                                    /> 
                                    <img 
                                        src={(src === undefined || src === null || src === '') 
                                            ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iA' + 
                                            'AAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAA' + 
                                            'A5SURBVHgB7dUxEQAgDATBgH9Z8QUKaPhhaPYMbHlVQd296rJZnwKDwWAw+NhI1haUmX4MBoPB4CdtYs0LGDW2IbcAAAAASUVORK5CYII=' 
                                            : src} 
                                        ref={ref => this.preview = ref} 
                                        className="btn_add"
                                        alt="사진"
                                        onClick={() => this.fileInput.click()}
                                    />
                                    {/* <button className="btn_add">사진 추가</button> */}
                                </td>
                            </tr>
                            <tr>
                                <th>연식</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="career" 
                                        value={career} 
                                        onChange={this.handleChange} 
                                        placeholder="연식" 
                                        className='size1'
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">수정하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(OwnerDetail);