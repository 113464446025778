import React from 'react';
import UnstyledSelectsMultiple from '../../components/Select';
import api from '../../api/api';
import axios from 'axios';
import Spinner from '../../components/Spinner';
import moment from 'moment';
import { connect } from 'react-redux';

class TermDetail extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = ({
            isLoading: false,
            isOpen: false,
            title: '',
            content: ''
        })
    }

    componentDidMount() {
        console.log(this.props.match.params.idx)
        this.getDetail()
    }

    getDetail = async () => {
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                  };
                api.get(`terms/detail/${this.props.match.params.idx}`, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        const res = result.data.data.result
                        this.setState({
                            title: res.term_title,
                            content: res.term_content,
                            isLoading: false
                        })
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = async () => {
        const { title, content } = this.state
        if (title.trim().length === 0) {
            alert('제목를 선택해주세요')
            return false
        } else if (content.trim().length === 0) {
            alert('내용을 입력해주세요')
            return false
        }
        await this.setState({
            isLoading: true
        }, () => {
            try {
                const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      'Access-Control-Allow-Origin': '*',
                      'Access-Control-Allow-Headers': '*',
                      'authorization': `Bearer ${this.props.token}`
                    },
                };
                const body = {
                    idx: this.props.match.params.idx,
                    title,
                    content
                }
                api.post(`terms/update`, body, config).then((result) => {
                    console.log(result)
                    if(result.data.success) {
                        alert('수정 성공')
                        this.props.history.goBack()
                    } else {
                        alert(result.data.message)
                    }
                })
            } catch (err) {
                alert('서버와 통신에 실패');
                console.log('err', err);
            } finally {
                this.setState({isLoading: false})
            }
        })
    }

    render() {
        const { title, content } = this.state
        return (
        <>
            <div className="content">
                <h2>약관상세</h2>
                <div className="location">
                    <span>약관관리</span>
                    <span>약관관리</span>
                    <span>약관상세</span>
                </div>            
                <div className="detail">
                    <table>
                        <caption>약관 상세</caption>
                        <tbody>
                            <tr>
                                <th>제목</th>
                                <td>
                                    <input 
                                        type="text" 
                                        name="title" 
                                        value={title} 
                                        onChange={this.handleChange} 
                                        placeholder="제목" 
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td>
                                    <textarea 
                                        name='content'
                                        value={content} 
                                        onChange={this.handleChange}  
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>미리보기</th>
                                <td>
                                    <div 
                                        style={{backgroundColor: '#FFF', width: '100%', height: 'auto'}}
                                        dangerouslySetInnerHTML={ {__html: content} }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button onClick={this.onSubmit} className="btn btn3 w170 top_btn">수정하기</button>
                </div>
                {this.state.isLoading && <Spinner />}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    token: state.auth.user.sessionToken
})
export default connect(mapStateToProps)(TermDetail);